import { TableProject } from "modules/projects/types";

export enum ROLES {
  ADMIN = "admin",
  MEMBER = "member",
  OWNER = "owner",
  NON_MEMBER = "non-member"
}

export enum INVITATION_TYPES {
  GROUP_MEMBER = "group-member",
  ORGANIZATION_OWNER = "organization-owner",
  ORGANIZATION_ADMINISTRATOR = "organization-administrator"
}

export type Organization = {
  id: string;
  name: string;
  role: ROLES;
  project_number_limit: number;
};

export type TableOrganization = {
  id: string;
  name: string;
  role: string;
};

export type GetOrganizationParams = {
  id: string;
  pollingId?: string;
};

export type GetOrganizationResponse = Organization;

export type GetOrganizationsParams = {
  pollingId?: string;
};

export type GetOrganizationsResponse = Organization[];

export type CreateOrganizationParams = {
  data: {
    name: string;
  };
};

export type CreateOrganizationResponse = Organization;

export type UpdateOrganizationParams = {
  id: string;
  data: {
    name: string;
  };
};

export type UpdateOrganizationResponse = Organization;

export type DeleteOrganizationParams = {
  id: string;
};

export type LeaveOrganizationParams = {
  organizationId: string;
};

export type Group = {
  id: string;
  name: string;
  projects: TableProject[];
};

export type GetGroupParams = {
  organizationId: string;
  groupId: string;
  pollingId?: string;
};

export type GetGroupResponse = Group;

export type GetGroupsParams = {
  organizationId: string;
  pollingId?: string;
};

export type GetGroupsResponse = Group[];

export type CreateGroupParams = {
  organizationId: string;
  data: {
    name: string;
  };
};

export type CreateGroupResponse = Group;

export type UpdateGroupParams = {
  organizationId: string;
  id: string;
  data: {
    name?: string;
  };
};

export type UpdateGroupResponse = Group;

export type DeleteGroupParams = {
  organizationId: string;
  id: string;
};

export type LeaveGroupParams = {
  organizationId: string;
  id: string;
};

export type User = {
  id: string;
  username: string;
  email: string;
  role: string;
  full_name: string;
};

export type TableUser = {
  id: string;
  email: string;
  formattedEmail: string;
  invitationId?: string;
  invitationType?: INVITATION_TYPES;
  role: string;
  fullName: string;
};

export type GetOrganizationUsersParams = {
  organizationId: string;
  pollingId?: string;
};
export type GetOrganizationUsersResponse = User[];

export type GetGroupUsersParams = {
  organizationId: string;
  groupId: string;
  pollingId?: string;
};

export type GetGroupUsersResponse = User[];

export type RemoveUserFromOrganizationParams = {
  organizationId: string;
  userId: string;
};

export type ChangeOrganizationOwnerParams = {
  organizationId: string;
  email: string;
};

export type RemoveUserFromGroupParams = {
  organizationId: string;
  groupId: string;
  userId: string;
};

export type SubmitHubSpotFormParams = {
  hutk: string;
};

export type Invitation = {
  id: string;
  createdAt: number;
  expiredAt: number;
  senderId: string;
  senderName: string;
  inviteeEmail: string;
  keycloakGroupIDToInvite: string;
  keycloakGroupNameToInvite: string;
  type: INVITATION_TYPES;
};

export type GetOrganizationInvitationsParams = {
  organizationId: string;
};

export type GetOrganizationInvitationsResponse = Invitation[];

export type GetOrganizationInvitationParams = {
  organizationId: string;
  invitationId: string;
};

export type GetOrganizationInvitationResponse = Invitation;

export type InviteUserToOrganizationParams = {
  organizationId: string;
  email: string;
};

export type InviteUserToOrganizationResponse = Invitation;

export type AcceptOrganizationInvitationParams = {
  organizationId: string;
  invitationId: string;
};

export type DeleteOrganizationInvitationParams = {
  organizationId: string;
  invitationId: string;
};

export type GetGroupInvitationsParams = {
  organizationId: string;
  groupId: string;
};

export type GetGroupInvitationsResponse = Invitation[];

export type GetGroupInvitationParams = {
  organizationId: string;
  groupId: string;
  invitationId: string;
};

export type GetGroupInvitationResponse = Invitation;

export type InviteUserToGroupParams = {
  organizationId: string;
  groupId: string;
  email: string;
};

export type InviteUserToGroupResponse = Invitation;

export type AcceptGroupInvitationParams = {
  organizationId: string;
  groupId: string;
  invitationId: string;
};

export type DeleteGroupInvitationParams = {
  organizationId: string;
  groupId: string;
  invitationId: string;
};

export type RegisterNewUserParams = {
  data: {
    email: string;
    first_name: string;
    last_name: string;
    creds_password: string;
    // email_verified: boolean;
    // is_otp_required: boolean;
    // creds_temporary: boolean;
    attributes: {
      country: string;
      locality: string;
      mobile: string;
      postal_code: string;
      region: string;
      street: string;
      company_name?: string;
      tax_number?: string;
    };
  };
};

export type RegisterNewUserResponse = { message: string };
