import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import { userSelector } from "modules/auth/selectors";
import * as enterprisesActions from "modules/enterprises/actions";
import {
  isOrganizationInvitationDeletingSelector,
  isOrganizationOwnerChangingSelector,
  isUserInvitingToOrganizationSelector,
  isUserRemovingFromOrganizationSelector,
  organizationSelector,
  tableOrganizationUsersSelector,
  userIdToRemoveFromOrganizationSelector,
  userRemovalFromOrganizationErrorSelector
} from "modules/enterprises/selectors";
import { INVITATION_TYPES, ROLES, TableUser } from "modules/enterprises/types";
import * as pollingActions from "modules/polling/actions";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { isNull } from "typeGuards/isNull";
import { isString } from "typeGuards/isString";
import { ref, string } from "yup";
import { ERROR_MESSAGES, REGEX, ROLE_LABELS, ROUTES } from "../../constants";
import { DIALOG_TYPES } from "./types";

const POLL_ID_PREFIX = "ADMINISTRATORS";

const POLL_IDS = {
  organizationUsers: "ORGANIZATION_USERS",
  organizationInvitations: "ORGANIZATION_INVITATIONS"
};

const tableColumns: TableColumn<TableUser>[] = [
  { key: "fullName", label: "Full name" },
  { key: "id", label: "ID" },
  { key: "formattedEmail", label: "E-mail" },
  { key: "role", label: "Role" }
];

const title = "Administrators";

export const Administrators: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
    id: string;
  }>();
  const history = useNavigate();
  const currentUser = useSelector(userSelector);
  const organization = useSelector(organizationSelector);
  const users = useSelector(tableOrganizationUsersSelector);
  const isUserInviting = useSelector(isUserInvitingToOrganizationSelector);
  const isInvitationDeleting = useSelector(
    isOrganizationInvitationDeletingSelector
  );
  const isInvitationOperationInProgress =
    isUserInviting || isInvitationDeleting;
  const previousIsInvitationOperationInProgress = usePrevious(
    isInvitationOperationInProgress
  );
  const isUserRemoving = useSelector(isUserRemovingFromOrganizationSelector);
  const previousIsUserRemoving = usePrevious(isUserRemoving);
  const isOrganizationOwnerChanging = useSelector(
    isOrganizationOwnerChangingSelector
  );
  const isUserOperationInProgress =
    isUserRemoving || isOrganizationOwnerChanging;
  const previousIsUserOperationInProgress = usePrevious(
    isUserOperationInProgress
  );
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.ADD_ADMINISTRATOR, isOpened: false });
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const userIdToRemove = useSelector(userIdToRemoveFromOrganizationSelector);
  const userRemovalError = useSelector(
    userRemovalFromOrganizationErrorSelector
  );
  const isCurrentUserRemoving =
    isString(userIdToRemove) &&
    userIdToRemove ===
      users?.find((user) => user.email === currentUser?.email)?.id;

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Administrators",
      url: generatePath(ROUTES.ADMINISTRATORS, {
        organizationId: matchParams.organizationId
      })
    }
  ];

  useMount(() => {
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.organizationUsers}`,
        action: enterprisesActions.getOrganizationUsers.started({
          organizationId: matchParams.organizationId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.organizationInvitations}`,
        action: enterprisesActions.getOrganizationInvitations.started({
          organizationId: matchParams.organizationId!
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
  });

  useEffect(() => {
    if (
      previousIsUserRemoving &&
      !isUserRemoving &&
      isCurrentUserRemoving &&
      isNull(userRemovalError)
    ) {
      history(ROUTES.ORGANIZATIONS);
    }
  }, [
    dispatch,
    history,
    isCurrentUserRemoving,
    previousIsUserRemoving,
    isUserRemoving,
    userRemovalError
  ]);

  useEffect(() => {
    if (
      previousIsUserOperationInProgress &&
      !isUserOperationInProgress &&
      !isCurrentUserRemoving
    ) {
      dispatch(
        enterprisesActions.getOrganizationUsers.started({
          organizationId: matchParams.organizationId!
        })
      );
    }
  }, [
    previousIsUserOperationInProgress,
    isUserOperationInProgress,
    dispatch,
    isCurrentUserRemoving,
    matchParams.organizationId
  ]);

  useEffect(() => {
    if (
      previousIsInvitationOperationInProgress &&
      !isInvitationOperationInProgress
    ) {
      dispatch(
        enterprisesActions.getOrganizationInvitations.started({
          organizationId: matchParams.organizationId!
        })
      );
    }
  }, [
    previousIsInvitationOperationInProgress,
    isInvitationOperationInProgress,
    dispatch,
    matchParams.organizationId
  ]);

  const handleAddAdministratorButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.ADD_ADMINISTRATOR,
      isOpened: true
    });
  }, []);

  const handleResendInvitationMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.RESEND_INVITATION,
      isOpened: true
    });
  }, []);

  const handleDeleteInvitationMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_INVITATION,
      isOpened: true
    });
  }, []);

  const handleResendOwnershipInvitationMenuItemClick = useCallback(
    (id: string) => {
      setSelectedItemId(id);
      setDialog({
        type: DIALOG_TYPES.RESEND_OWNERSHIP_INVITATION,
        isOpened: true
      });
    },
    []
  );

  const handleDeleteOwnershipInvitationMenuItemClick = useCallback(
    (id: string) => {
      setSelectedItemId(id);
      setDialog({
        type: DIALOG_TYPES.DELETE_OWNERSHIP_INVITATION,
        isOpened: true
      });
    },
    []
  );

  const handleRemoveAdministratorMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.REMOVE_ADMINISTRATOR,
      isOpened: true
    });
  }, []);

  const handleChangeOrganizationOwnerMenuItemClick = useCallback(
    (id: string) => {
      setSelectedItemId(id);
      setDialog({
        type: DIALOG_TYPES.CHANGE_ORGANIZATION_OWNER,
        isOpened: true
      });
    },
    []
  );

  const handleConfirmAddAdministrator = useCallback(
    (data: { email: string }) => {
      dispatch(
        enterprisesActions.inviteUserToOrganization.started({
          organizationId: matchParams.organizationId!,
          email: data.email
        })
      );
      handleCloseDialog();
    },
    [dispatch, matchParams.organizationId, handleCloseDialog]
  );

  const handleConfirmRemoveAdministrator = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        enterprisesActions.removeUserFromOrganization.started({
          organizationId: matchParams.organizationId!,
          userId: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [selectedItemId, dispatch, matchParams.organizationId, handleCloseDialog]);

  const handleConfirmChangeOrganizationOwner = useCallback(() => {
    if (selectedItemId) {
      const userEmailToInvite = users?.find(
        (user) => user.id === selectedItemId
      )?.email;
      if (userEmailToInvite) {
        dispatch(
          enterprisesActions.changeOrganizationOwner.started({
            organizationId: matchParams.organizationId!,
            email: userEmailToInvite
          })
        );
      }
    }
    handleCloseDialog();
  }, [
    selectedItemId,
    dispatch,
    matchParams.organizationId,
    handleCloseDialog,
    users
  ]);

  const handleConfirmResendInvitation = useCallback(() => {
    if (selectedItemId) {
      const userToResend = users?.find((user) => user.id === selectedItemId);
      if (userToResend) {
        dispatch(
          enterprisesActions.inviteUserToOrganization.started({
            organizationId: matchParams.organizationId!,
            email: userToResend.email
          })
        );
      }
    }
    handleCloseDialog();
  }, [
    selectedItemId,
    dispatch,
    matchParams.organizationId,
    handleCloseDialog,
    users
  ]);

  const handleConfirmDeleteInvitation = useCallback(() => {
    if (selectedItemId) {
      const userToDelete = users?.find((user) => user.id === selectedItemId);
      if (userToDelete?.invitationId) {
        dispatch(
          enterprisesActions.deleteOrganizationInvitation.started({
            organizationId: matchParams.organizationId!,
            invitationId: userToDelete.invitationId
          })
        );
      }
    }
    handleCloseDialog();
  }, [
    selectedItemId,
    dispatch,
    matchParams.organizationId,
    users,
    handleCloseDialog
  ]);

  const tableActions: TableRowActionsMenuItem<TableUser>[] = [
    {
      label: "Remove",
      isDisabled: (user) =>
        Boolean(user.invitationId) ||
        Boolean(user.role === ROLE_LABELS[ROLES.OWNER]),
      handler: handleRemoveAdministratorMenuItemClick
    },
    {
      label: "Transfer organization",
      isDisabled: (user) =>
        Boolean(user.invitationId) ||
        user.role === ROLE_LABELS[ROLES.OWNER] ||
        Boolean(
          isString(currentUser?.email) &&
            currentUser?.email !==
              users?.find((user) => user.role === ROLE_LABELS[ROLES.OWNER])
                ?.email
        ),
      handler: handleChangeOrganizationOwnerMenuItemClick
    },
    {
      label: "Resend ownership invitation",
      isDisabled: (user) =>
        Boolean(
          !user.invitationId ||
            user.invitationType !== INVITATION_TYPES.ORGANIZATION_OWNER
        ),
      handler: handleResendOwnershipInvitationMenuItemClick
    },
    {
      label: "Delete ownership invitation",
      isDisabled: (user) =>
        Boolean(
          !user.invitationId ||
            user.invitationType !== INVITATION_TYPES.ORGANIZATION_OWNER
        ),
      handler: handleDeleteOwnershipInvitationMenuItemClick
    },
    {
      label: "Resend invitation",
      isDisabled: (user) =>
        Boolean(
          !user.invitationId ||
            user.invitationType !== INVITATION_TYPES.ORGANIZATION_ADMINISTRATOR
        ),
      handler: handleResendInvitationMenuItemClick
    },
    {
      label: "Delete invitation",
      isDisabled: (user) =>
        Boolean(
          !user.invitationId ||
            user.invitationType !== INVITATION_TYPES.ORGANIZATION_ADMINISTRATOR
        ),
      handler: handleDeleteInvitationMenuItemClick
    }
  ];

  const previousSelectedItemId = usePrevious(selectedItemId);
  const currentItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const currentAdministratorName = users?.find(
    (user) => user.id === currentItemId
  )?.email;

  const [isRegisterNewUserDialogOpened, setIsRegisterNewUserDialogOpened] =
    useState(false);

  const handleRegisterNewUser = useCallback(() => {
    setIsRegisterNewUserDialogOpened(true);
  }, []);

  const handleCloseRegisterNewUserDialog = useCallback(() => {
    setIsRegisterNewUserDialogOpened(false);
  }, []);

  const handleConfirmRegisterNewUser = useCallback(
    (data: {
      email_new_user: string;
      first_name: string;
      last_name: string;
      // email_verified: boolean;
      // is_otp_required: boolean;
      // creds_temporary: boolean;
      password: string;
      country: string;
      locality: string;
      mobile: string;
      postal_code: string;
      region: string;
      street: string;
      company_name?: string;
      tax_number?: string;
    }) => {
      dispatch(
        enterprisesActions.registerNewUser.started({
          data: {
            email: data.email_new_user,
            // email_verified: data.email_verified,
            // is_otp_required: data.is_otp_required,
            // creds_temporary: data.creds_temporary,
            first_name: data.first_name,
            last_name: data.last_name,
            creds_password: data.password,
            attributes: {
              country: data.country,
              locality: data.locality,
              mobile: data.mobile,
              postal_code: data.postal_code,
              region: data.region,
              street: data.street,
              company_name: data.company_name,
              tax_number: data.tax_number
            }
          }
        })
      );
      handleCloseRegisterNewUserDialog();
    },
    [dispatch, handleCloseRegisterNewUserDialog]
  );

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.ADD_ADMINISTRATOR]: {
      onConfirm: handleConfirmAddAdministrator,
      title: "Add administrator",
      confirmButtonLabel: "Send invitation",
      fields: [
        {
          name: "email",
          type: FIELD_TYPES.TEXT,
          label: "E-mail address",
          rules: string()
            .required()
            .matches(REGEX.EMAIL_ADDRESS, ERROR_MESSAGES.EMAIL_ADDRESS)
        },
        {
          name: "info",
          type: FIELD_TYPES.LABEL,
          label: `\nℹ️ Only already registered users can be assigned as administrators for the selected organization.\n\n`
        },
        // {
        //   name: "info2",
        //   type: FIELD_TYPES.LABEL,
        //   label: `\nℹ️ If you want to assign a new user as an administrator, please register him first by filling in the fields below.`
        // },
        {
          name: "register_new",
          type: FIELD_TYPES.CHECKBOX,
          defaultValue: false,
          fullWidth: true,
          onCreate: handleRegisterNewUser,
          label: "Register new user"
        }
      ]
    },
    [DIALOG_TYPES.REMOVE_ADMINISTRATOR]: {
      onConfirm: handleConfirmRemoveAdministrator,
      title: `Are you sure you want to remove "${
        currentAdministratorName ?? "selected user"
      }" from the administrators?`,
      confirmButtonLabel: "Remove"
    },
    [DIALOG_TYPES.CHANGE_ORGANIZATION_OWNER]: {
      onConfirm: handleConfirmChangeOrganizationOwner,
      title: `Are you sure you want to send an invitation to the "${
        currentAdministratorName ?? "selected user"
      }" to become organization owner?`,
      confirmButtonLabel: "Send"
    },
    [DIALOG_TYPES.RESEND_INVITATION]: {
      onConfirm: handleConfirmResendInvitation,
      title: `Are you sure you want to resend invitation for "${
        currentAdministratorName ?? "selected user"
      }"?`,
      confirmButtonLabel: "Resend"
    },
    [DIALOG_TYPES.DELETE_INVITATION]: {
      onConfirm: handleConfirmDeleteInvitation,
      title: `Are you sure you want to delete invitation for "${
        currentAdministratorName ?? "selected user"
      }"?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.RESEND_OWNERSHIP_INVITATION]: {
      onConfirm: handleConfirmResendInvitation,
      title: `Are you sure you want to resend invitation for "${
        currentAdministratorName ?? "selected user"
      }" to become organization owner?`,
      confirmButtonLabel: "Resend"
    },
    [DIALOG_TYPES.DELETE_OWNERSHIP_INVITATION]: {
      onConfirm: handleConfirmDeleteInvitation,
      title: `Are you sure you want to delete invitation for "${
        currentAdministratorName ?? "selected user"
      }" to become organization owner?`,
      confirmButtonLabel: "Delete"
    }
  };

  return (
    <>
      <Head title={title} />
      {organization && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      <Typography variant={"h4"} component={"h2"}>
        {title}
      </Typography>
      <Table
        isSearchEnabled={true}
        isSortingEnabled={true}
        rows={users || []}
        columns={tableColumns}
        actions={tableActions}
        isLoading={!users}
        toolbarItems={
          <Button
            onClick={handleAddAdministratorButtonClick}
            variant={"contained"}
            disabled={!currentUser}
          >
            Add administrator
          </Button>
        }
      />
      <FormDialog
        isOpened={dialog.isOpened}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        onCancel={handleCloseDialog}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
      <FormDialog
        isOpened={isRegisterNewUserDialogOpened}
        onCancel={handleCloseRegisterNewUserDialog}
        fields={[
          {
            name: "email_new_user",
            type: FIELD_TYPES.TEXT,
            label: "E-mail address",
            rules: string()
              .required()
              .matches(REGEX.EMAIL_ADDRESS, ERROR_MESSAGES.EMAIL_ADDRESS)
          },
          {
            name: "password",
            type: FIELD_TYPES.PASSWORD,
            label: "Password",
            autocomplete: "new-password",
            rules: string()
              .required()
              .matches(REGEX.PASSWORD, ERROR_MESSAGES.PASSWORD)
          },
          {
            name: "confirm_password",
            type: FIELD_TYPES.PASSWORD,
            label: "Confirm password",
            autocomplete: "new-password",
            rules: string().oneOf(
              [ref("password")],
              ERROR_MESSAGES.PASSWORDS_MUST_MATCH
            )
          },
          // {
          //   name: "creds_temporary",
          //   type: FIELD_TYPES.TOGGLE,
          //   defaultValue: true,
          //   label: "Change password on next login"
          // },
          {
            name: "divider",
            type: FIELD_TYPES.DIVIDER,
            label: "Personal Information"
          },
          {
            name: "first_name",
            type: FIELD_TYPES.TEXT,
            label: "First name",
            rules: string().required()
          },
          {
            name: "last_name",
            type: FIELD_TYPES.TEXT,
            label: "Last name",
            rules: string().required()
          },
          {
            name: "mobile",
            type: FIELD_TYPES.TEXT,
            label: "Mobile Number",
            rules: string()
          },
          {
            name: "divider2",
            type: FIELD_TYPES.DIVIDER,
            label: "Address"
          },
          {
            name: "street",
            type: FIELD_TYPES.TEXT,
            label: "Street",
            defaultValue: currentUser?.attributes?.["info:street"]?.[0] || "",
            rules: string()
          },
          {
            name: "locality",
            type: FIELD_TYPES.TEXT,
            defaultValue: currentUser?.attributes?.["info:locality"]?.[0] || "",
            label: "City or Locality",
            rules: string()
          },
          {
            name: "region",
            type: FIELD_TYPES.TEXT,
            defaultValue: currentUser?.attributes?.["info:region"]?.[0] || "",
            label: "State, Province, or Region",
            rules: string()
          },
          {
            name: "country",
            type: FIELD_TYPES.TEXT,
            defaultValue: currentUser?.attributes?.["info:country"]?.[0] || "",
            label: "Country",
            rules: string()
          },
          {
            name: "postal_code",
            type: FIELD_TYPES.TEXT,
            defaultValue:
              currentUser?.attributes?.["info:postal_code"]?.[0] || "",
            label: "Zip or Postal code",
            rules: string()
          },
          {
            name: "divider3",
            type: FIELD_TYPES.DIVIDER,
            label: "Company"
          },
          {
            name: "company_name",
            type: FIELD_TYPES.TEXT,
            label: "Company name",
            readOnly:
              currentUser?.attributes?.["info:companyName"] &&
              currentUser?.attributes?.["info:companyName"]?.[0] !== "",
            defaultValue:
              currentUser?.attributes?.["info:companyName"]?.[0] || "",
            rules: string()
          },
          {
            name: "tax_number",
            type: FIELD_TYPES.TEXT,
            readOnly:
              currentUser?.attributes?.["info:companyTaxNumber"] &&
              currentUser?.attributes?.["info:companyTaxNumber"]?.[0] !== "",
            defaultValue:
              currentUser?.attributes?.["info:companyTaxNumber"]?.[0] || "",
            label: "Company Tax number",
            rules: string()
          }
        ]}
        onConfirm={handleConfirmRegisterNewUser}
        title={"Register New User"}
        confirmButtonLabel={"Register"}
      />
    </>
  );
};
