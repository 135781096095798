import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Loader } from "components/common/Loader";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as networksActions from "modules/networks/actions";
import {
  isNetworkDeletingSelector,
  isNetworkUpdatingSelector,
  isSubnetCreatingSelector,
  isSubnetDeletingSelector,
  isSubnetUpdatingSelector,
  networkSelector,
  networkSubnetsSelector
} from "modules/networks/selectors";
import { Subnet } from "modules/networks/types";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { projectSelector } from "modules/projects/selectors";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { generateSearchString } from "utils/generateSearchString";
import { getParentPath } from "utils/getParentPath";
import { splitMultilineCommaSeparatedList } from "utils/splitMultilineCommaSeparatedList";
import { validateName } from "utils/validateName";
import { string } from "yup";
import {
  ENTITY_NAME_LENGTH,
  REGEX,
  ROUTES,
  SPECIAL_NAMES
} from "../../constants";
import * as s from "./styles";
import { DIALOG_TYPES, TABS } from "./types";

const POLL_ID_PREFIX = "NETWORK";

const POLL_IDS = {
  network: "NETWORK",
  networkSubnets: "NETWORK_SUBNETS"
};

const subnetTableColumns: TableColumn<Subnet>[] = [
  { key: "name", label: "Name" },
  { key: "id", label: "ID" },
  { key: "cidr", label: "CIDR" },
  { key: "gateway_ip", label: "Gateway IP" }
];

const TAB_TITLES: { [key in TABS]: string } = {
  [TABS.SUBNETS]: "Subnets"
};

export const Network: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
    regionId: string;
    projectId: string;
    networkId: string;
  }>();
  const history = useNavigate();
  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const network = useSelector(networkSelector);
  const subnets = useSelector(networkSubnetsSelector);
  const isNetworkUpdating = useSelector(isNetworkUpdatingSelector);
  const isNetworkDeleting = useSelector(isNetworkDeletingSelector);
  const isSubnetCreating = useSelector(isSubnetCreatingSelector);
  const isSubnetUpdating = useSelector(isSubnetUpdatingSelector);
  const isSubnetDeleting = useSelector(isSubnetDeletingSelector);
  const isNetworkOperationInProgress = isNetworkUpdating || isNetworkDeleting;
  const isSubnetOperationInProgress =
    isSubnetCreating || isSubnetUpdating || isSubnetDeleting;
  const previousIsNetworkOperationInProgress = usePrevious(
    isNetworkOperationInProgress
  );
  const previousIsSubnetOperationInProgress = usePrevious(
    isSubnetOperationInProgress
  );
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.EDIT_NETWORK, isOpened: false });
  const [activeTabIndex, setActiveTabIndex] = useState(TABS.SUBNETS);

  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "Networking",
      url: generatePath(ROUTES.NETWORKING, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: network?.name || "",
      url: generatePath(ROUTES.NETWORK, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        networkId: matchParams.networkId
      })
    }
  ];

  const handleEditNetworkButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.EDIT_NETWORK,
      isOpened: true
    });
  }, []);

  const handleDeleteNetworkButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.DELETE_NETWORK,
      isOpened: true
    });
  }, []);

  const handleCreateSubnetButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_SUBNET,
      isOpened: true
    });
  }, []);

  const handleEditSubnetMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT_SUBNET,
      isOpened: true
    });
  }, []);

  const handleDeleteSubnetMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_SUBNET,
      isOpened: true
    });
  }, []);

  const subnetTableActions: TableRowActionsMenuItem<Subnet>[] = [
    {
      label: "Edit",
      handler: handleEditSubnetMenuItemClick
    },
    {
      label: "Delete",
      handler: handleDeleteSubnetMenuItemClick
    }
  ];

  useMount(() => {
    history({
      search: generateSearchString({
        tab: TAB_TITLES[TABS.SUBNETS]
      })
    });
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.network}`,
        action: networksActions.getNetwork.started({
          projectId: matchParams.projectId!,
          regionId: matchParams.regionId!,
          id: matchParams.networkId!
        })
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.networkSubnets}`,
        action: networksActions.getNetworkSubnets.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          networkId: matchParams.networkId!
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(projectsActions.clear());
    dispatch(networksActions.clear());
  });

  useEffect(() => {
    if (previousIsNetworkOperationInProgress && !isNetworkOperationInProgress) {
      if (!network) {
        history(getParentPath(location.pathname));
      } else {
        dispatch(
          networksActions.getNetwork.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: matchParams.networkId!
          })
        );
      }
    }
  }, [
    network,
    history,
    previousIsNetworkOperationInProgress,
    isNetworkOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.networkId,
    matchParams.regionId
  ]);

  useEffect(() => {
    if (previousIsSubnetOperationInProgress && !isSubnetOperationInProgress) {
      dispatch(
        networksActions.getSubnets.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [
    previousIsSubnetOperationInProgress,
    isSubnetOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.regionId
  ]);

  const handleConfirmEditNetwork = useCallback(
    (data: { name: string; description: string }) => {
      dispatch(
        networksActions.updateNetwork.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: matchParams.networkId!,
          data: {
            ...data,
            description: data.name.trim()
          }
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.networkId,
      matchParams.projectId,
      matchParams.regionId,
      handleCloseDialog
    ]
  );

  const handleConfirmDeleteNetwork = useCallback(() => {
    dispatch(
      networksActions.deleteNetwork.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        id: matchParams.networkId!
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.networkId,
    matchParams.regionId,
    handleCloseDialog
  ]);

  const handleConfirmCreateSubnet = useCallback(
    (data: {
      allocation_pools: string;
      cidr: string;
      dns_nameservers: string;
      enable_dhcp: boolean;
      disableGateway: boolean;
      gateway_ip: string;
      host_routes: string;
      name: string;
    }) => {
      dispatch(
        networksActions.createSubnet.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          data: {
            name: data.name,
            cidr: data.cidr.trim(),
            ...(data.allocation_pools
              ? {
                  allocation_pools: splitMultilineCommaSeparatedList(
                    data.allocation_pools
                  ).map((pool) => {
                    const [start, end] = pool.replace(/\s/g, "").split("-");
                    return {
                      start,
                      end
                    };
                  })
                }
              : {}),
            ...(data.dns_nameservers
              ? {
                  dns_nameservers: splitMultilineCommaSeparatedList(
                    data.dns_nameservers
                  )
                }
              : {}),
            enable_dhcp: data.enable_dhcp,
            ...(data.host_routes
              ? {
                  host_routes: splitMultilineCommaSeparatedList(
                    data.host_routes
                  ).map((route) => {
                    const [destination, nexthop] = route
                      .replace(/\s/g, "")
                      .split("-");
                    return {
                      destination,
                      nexthop
                    };
                  })
                }
              : {}),
            network_id: matchParams.networkId!,
            gateway_ip: data.disableGateway
              ? ""
              : data.gateway_ip.trim() === ""
                ? null
                : data.gateway_ip.trim()
          }
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.networkId,
      matchParams.projectId,
      matchParams.regionId,
      handleCloseDialog
    ]
  );

  const handleConfirmEditSubnet = useCallback(
    (data: {
      allocation_pools: string;
      dns_nameservers: string;
      enable_dhcp: boolean;
      gateway_ip: string;
      host_routes: string;
      name: string;
      disableGateway: boolean;
    }) => {
      if (selectedItemId) {
        dispatch(
          networksActions.updateSubnet.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: selectedItemId,
            data: {
              name: data.name,
              allocation_pools: data.allocation_pools
                ? splitMultilineCommaSeparatedList(data.allocation_pools).map(
                    (pool) => {
                      const [start, end] = pool.replace(/\s/g, "").split("-");
                      return {
                        start,
                        end
                      };
                    }
                  )
                : [],
              dns_nameservers: data.dns_nameservers
                ? splitMultilineCommaSeparatedList(data.dns_nameservers)
                : [],
              enable_dhcp: data.enable_dhcp,
              host_routes: data.host_routes
                ? splitMultilineCommaSeparatedList(data.host_routes).map(
                    (route) => {
                      const [destination, nexthop] = route
                        .replace(/\s/g, "")
                        .split("-");
                      return {
                        destination,
                        nexthop
                      };
                    }
                  )
                : [],
              gateway_ip: data.disableGateway
                ? ""
                : data.gateway_ip.trim() === ""
                  ? null
                  : data.gateway_ip.trim()
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      selectedItemId,
      dispatch,
      matchParams.projectId,
      matchParams.regionId,
      handleCloseDialog
    ]
  );

  const handleConfirmDeleteSubnet = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        networksActions.deleteSubnet.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: selectedItemId
        })
      );
      handleCloseDialog();
    }
  }, [
    dispatch,
    selectedItemId,
    matchParams.projectId,
    matchParams.regionId,
    handleCloseDialog
  ]);

  const previousSelectedItemId = usePrevious(selectedItemId);
  const deletingItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const deletingSubnetName = subnets?.find(
    (subnet) => subnet.id === deletingItemId
  )?.name;

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.EDIT_NETWORK]: {
      onConfirm: handleConfirmEditNetwork,
      title: "Edit network",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue: network?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          defaultValue: network?.description || "",
          rules: string()
        }
      ]
    },
    [DIALOG_TYPES.DELETE_NETWORK]: {
      onConfirm: handleConfirmDeleteNetwork,
      title: `Are you sure you want to delete "${
        network?.name ?? "selected"
      }" network?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.CREATE_SUBNET]: {
      onConfirm: handleConfirmCreateSubnet,
      title: "Create subnet",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "enable_dhcp",
          type: FIELD_TYPES.CHECKBOX,
          defaultValue: true,
          label: "Enable DHCP"
        },
        {
          name: "cidr",
          type: FIELD_TYPES.TEXT,
          label: "CIDR",
          rules: string()
            .required()
            .matches(
              REGEX.IP_ADDRESS_WITH_NET_MASK,
              'CIDR address must have the valid format (e.g. "192.168.1.0/24")'
            )
        },
        {
          name: "allocation_pools",
          type: FIELD_TYPES.MULTILINE_TEXT,
          label: "Allocation pools",
          rules: string().test(
            "allocationPoolsValidFormat",
            'Allocation pool must have the valid format (e.g., "192.168.1.100-192.168.1.120") and one entry per line',
            (value) =>
              !value ||
              Boolean(
                value &&
                  splitMultilineCommaSeparatedList(value)
                    .map((record) => record.replace(/\s/g, "").split("-"))
                    .flat()
                    .every((record) => REGEX.IP_ADDRESS.test(record))
              )
          )
        },
        {
          name: "dns_nameservers",
          type: FIELD_TYPES.MULTILINE_TEXT,
          label: "DNS nameservers",
          rules: string().test(
            "DNSNameserversValidFormat",
            'DNS nameserver must have the valid format (e.g., "8.8.8.8") and one entry per line',
            (value) =>
              !value ||
              Boolean(
                value &&
                  splitMultilineCommaSeparatedList(value).every((record) =>
                    REGEX.IP_ADDRESS.test(record)
                  )
              )
          )
        },
        {
          name: "gateway_ip",
          type: FIELD_TYPES.TEXT,
          label: "Gateway IP",
          rules: string().matches(REGEX.IP_ADDRESS, {
            message:
              'Gateway IP must have the valid format (e.g., "192.168.1.102")',
            excludeEmptyString: true
          })
        },
        {
          name: "disableGateway",
          type: FIELD_TYPES.CHECKBOX,
          label: "Disable gateway"
        },
        {
          name: "host_routes",
          type: FIELD_TYPES.MULTILINE_TEXT,
          label: "Host routes",
          rules: string().test(
            "hostRoutesValidFormat",
            'Host route must have the valid format (e.g., "192.168.200.0/24-10.56.1.254") and one entry per line',
            (value) =>
              !value ||
              Boolean(
                value &&
                  splitMultilineCommaSeparatedList(value)
                    .map((route) => {
                      const [destination, nexthop] = route
                        .replace(/\s/g, "")
                        .split("-");
                      return {
                        destination,
                        nexthop
                      };
                    })
                    .every(
                      (route) =>
                        REGEX.IP_ADDRESS_WITH_NET_MASK.test(
                          route.destination
                        ) && REGEX.IP_ADDRESS.test(route.nexthop)
                    )
              )
          )
        }
      ]
    },
    [DIALOG_TYPES.EDIT_SUBNET]: {
      onConfirm: handleConfirmEditSubnet,
      title: "Edit subnet",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            subnets?.find((subnet) => subnet.id === selectedItemId)?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "enable_dhcp",
          type: FIELD_TYPES.CHECKBOX,
          label: "Enable DHCP",
          defaultValue: subnets?.find((subnet) => subnet.id === selectedItemId)
            ?.enable_dhcp
        },
        {
          name: "allocation_pools",
          type: FIELD_TYPES.TEXT,
          label: "Allocation pools",
          defaultValue:
            subnets
              ?.find((subnet) => subnet.id === selectedItemId)
              ?.allocation_pools.map((pool) => `${pool.start}-${pool.end}`)
              .join(",\n") || "",
          rules: string().test(
            "allocationPoolsValidFormat",
            'Allocation pool must have the valid format (e.g., "192.168.1.100-192.168.1.120") and one entry per line',
            (value) =>
              !value ||
              Boolean(
                value &&
                  splitMultilineCommaSeparatedList(value)
                    .map((record) => record.replace(/\s/g, "").split("-"))
                    .flat()
                    .every((record) => REGEX.IP_ADDRESS.test(record))
              )
          )
        },
        {
          name: "dns_nameservers",
          type: FIELD_TYPES.TEXT,
          label: "DNS nameservers",
          defaultValue:
            subnets
              ?.find((subnet) => subnet.id === selectedItemId)
              ?.dns_nameservers.join(",\n") || "",
          rules: string().test(
            "DNSNameserversValidFormat",
            'DNS nameserver must have the valid format (e.g., "8.8.8.8") and one entry per line',
            (value) =>
              !value ||
              Boolean(
                value &&
                  splitMultilineCommaSeparatedList(value).every((record) =>
                    REGEX.IP_ADDRESS.test(record)
                  )
              )
          )
        },
        {
          name: "gateway_ip",
          type: FIELD_TYPES.TEXT,
          label: "Gateway IP",
          defaultValue:
            subnets?.find((subnet) => subnet.id === selectedItemId)
              ?.gateway_ip || "",
          rules: string().matches(REGEX.IP_ADDRESS, {
            message:
              'Gateway IP must have the valid format (e.g., "192.168.1.102")',
            excludeEmptyString: true
          })
        },
        {
          name: "disableGateway",
          type: FIELD_TYPES.CHECKBOX,
          label: "Disable gateway",
          defaultValue:
            subnets?.find((subnet) => subnet.id === selectedItemId)
              ?.gateway_ip === ""
        },
        {
          name: "host_routes",
          type: FIELD_TYPES.TEXT,
          label: "Host routes",
          defaultValue:
            subnets
              ?.find((subnet) => subnet.id === selectedItemId)
              ?.host_routes.map(
                (route) => `${route.destination}-${route.nexthop}`
              )
              .join(",\n") || "",
          rules: string().test(
            "hostRoutesValidFormat",
            'Host route must have the valid format (e.g., "192.168.200.0/24-10.56.1.254") and one entry per line',
            (value) =>
              !value ||
              Boolean(
                value &&
                  splitMultilineCommaSeparatedList(value)
                    .map((route) => {
                      const [destination, nexthop] = route
                        .replace(/\s/g, "")
                        .split("-");
                      return {
                        destination,
                        nexthop
                      };
                    })
                    .every(
                      (route) =>
                        REGEX.IP_ADDRESS_WITH_NET_MASK.test(
                          route.destination
                        ) && REGEX.IP_ADDRESS.test(route.nexthop)
                    )
              )
          )
        }
      ]
    },
    [DIALOG_TYPES.DELETE_SUBNET]: {
      onConfirm: handleConfirmDeleteSubnet,
      title: `Are you sure you want to delete "${
        deletingSubnetName ?? "selected"
      }" subnet?`,
      confirmButtonLabel: "Delete"
    }
  };

  const tabContent = [
    <Table<Subnet>
      isSearchEnabled={true}
      isSortingEnabled={true}
      key={"subnetTable"}
      rows={subnets || []}
      columns={subnetTableColumns}
      actions={
        network?.name !== SPECIAL_NAMES.NETWORK ? subnetTableActions : undefined
      }
      isLoading={!subnets}
      toolbarItems={
        network?.name !== SPECIAL_NAMES.NETWORK && (
          <Button onClick={handleCreateSubnetButtonClick} variant={"contained"}>
            Create subnet
          </Button>
        )
      }
    />
  ];

  const title = network?.name;

  return (
    <>
      <Head title={title} />
      {network ? (
        <>
          {organization && project && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          <s.SummaryContainer>
            <s.SummaryColumn>
              <s.Title variant={"h4"} component={"h2"}>
                {title}
              </s.Title>
              <s.SummaryRow>
                <s.DetailsTitle>ID: </s.DetailsTitle>
                <s.DetailsInfoColored>{network.id}</s.DetailsInfoColored>
              </s.SummaryRow>
            </s.SummaryColumn>
            {network?.name !== SPECIAL_NAMES.NETWORK && (
              <s.ActionsContainer>
                <Tooltip title={"Edit"} arrow>
                  <span>
                    <IconButton
                      onClick={handleEditNetworkButtonClick}
                      color={"inherit"}
                      // title={"Edit"}
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={"Delete"} arrow>
                  <span>
                    <IconButton
                      onClick={handleDeleteNetworkButtonClick}
                      color={"inherit"}
                      // title={"Delete"}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </s.ActionsContainer>
            )}
          </s.SummaryContainer>
          <Tabs value={activeTabIndex} onChange={handleChangeTab}>
            {Object.values(TAB_TITLES).map((tabTitle) => (
              <Tab key={tabTitle} label={tabTitle} />
            ))}
          </Tabs>
          {tabContent[activeTabIndex]}
        </>
      ) : (
        <Loader text={"Loading data..."} />
      )}
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
    </>
  );
};
