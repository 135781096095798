import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { Group, Invitation, Organization, User } from "./types";

export type Reducer = {
  organization: Organization | null;
  organizations: Organization[] | null;
  organizationInvitations: Invitation[] | null;
  organizationInvitation: Invitation | null;
  areOrganizationInvitationsLoading: boolean;
  isOrganizationInvitationLoading: boolean;
  isUserInvitingToOrganization: boolean;
  isOrganizationInvitationAccepting: boolean;
  isOrganizationInvitationDeleting: boolean;
  organizationInvitationAcceptanceError: string | null;
  isOrganizationLoading: boolean;
  areOrganizationsLoading: boolean;
  isOrganizationCreating: boolean;
  isOrganizationUpdating: boolean;
  isOrganizationDeleting: boolean;
  isOrganizationOwnerChanging: boolean;
  group: Group | null;
  groups: Group[] | null;
  groupInvitations: Invitation[] | null;
  areGroupInvitationsLoading: boolean;
  groupInvitation: Invitation | null;
  isGroupInvitationLoading: boolean;
  isUserInvitingToGroup: boolean;
  isGroupInvitationAccepting: boolean;
  isGroupInvitationDeleting: boolean;
  groupInvitationAcceptanceError: string | null;
  isGroupLoading: boolean;
  areGroupsLoading: boolean;
  isGroupCreating: boolean;
  isGroupUpdating: boolean;
  isGroupDeleting: boolean;
  isGroupLeaving: boolean;
  areGroupUsersLoading: boolean;
  areOrganizationUsersLoading: boolean;
  isUserRemovingFromGroup: boolean;
  groupUsers: User[] | null;
  organizationUsers: User[] | null;
  isUserRemovingFromOrganization: boolean;
  userIdToRemoveFromOrganization: string | null;
  userRemovalFromOrganizationError: string | null;
  isHubspotFormSubmitting: boolean;
  isNewUserRegistering: boolean;
};

const initialState: Reducer = {
  organization: null,
  organizations: null,
  organizationInvitations: null,
  organizationInvitation: null,
  areOrganizationInvitationsLoading: false,
  isOrganizationInvitationLoading: false,
  isUserInvitingToOrganization: false,
  isOrganizationInvitationAccepting: false,
  isOrganizationInvitationDeleting: false,
  organizationInvitationAcceptanceError: null,
  isOrganizationLoading: false,
  areOrganizationsLoading: false,
  isOrganizationCreating: false,
  isOrganizationUpdating: false,
  isOrganizationDeleting: false,
  isOrganizationOwnerChanging: false,
  group: null,
  groups: null,
  groupInvitations: null,
  areGroupInvitationsLoading: false,
  groupInvitation: null,
  isGroupInvitationLoading: false,
  isUserInvitingToGroup: false,
  isGroupInvitationAccepting: false,
  isGroupInvitationDeleting: false,
  groupInvitationAcceptanceError: null,
  isGroupLoading: false,
  areGroupsLoading: false,
  isGroupCreating: false,
  isGroupUpdating: false,
  isGroupDeleting: false,
  isGroupLeaving: false,
  areGroupUsersLoading: false,
  areOrganizationUsersLoading: false,
  isUserRemovingFromGroup: false,
  isUserRemovingFromOrganization: false,
  userIdToRemoveFromOrganization: null,
  userRemovalFromOrganizationError: null,
  groupUsers: null,
  organizationUsers: null,
  isHubspotFormSubmitting: false,
  isNewUserRegistering: false
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getOrganization.started,
    (state): Reducer => ({
      ...state,
      isOrganizationLoading: true
    })
  )
  .case(
    actions.getOrganization.done,
    (state, payload): Reducer => ({
      ...state,
      organization: payload.result,
      isOrganizationLoading: false
    })
  )
  .case(
    actions.getOrganization.failed,
    (state): Reducer => ({
      ...state,
      isOrganizationLoading: false
    })
  )
  .case(
    actions.getOrganizations.started,
    (state): Reducer => ({
      ...state,
      areOrganizationsLoading: true
    })
  )
  .case(
    actions.getOrganizations.done,
    (state, payload): Reducer => ({
      ...state,
      organizations: payload.result,
      areOrganizationsLoading: false
    })
  )
  .case(
    actions.getOrganizations.failed,
    (state): Reducer => ({
      ...state,
      areOrganizationsLoading: false
    })
  )
  .case(
    actions.createOrganization.started,
    (state): Reducer => ({
      ...state,
      isOrganizationCreating: true
    })
  )
  .case(
    actions.createOrganization.done,
    (state): Reducer => ({
      ...state,
      isOrganizationCreating: false
    })
  )
  .case(
    actions.createOrganization.failed,
    (state): Reducer => ({
      ...state,
      isOrganizationCreating: false
    })
  )
  .case(
    actions.updateOrganization.started,
    (state): Reducer => ({
      ...state,
      isOrganizationUpdating: true
    })
  )
  .case(
    actions.updateOrganization.done,
    (state): Reducer => ({
      ...state,
      isOrganizationUpdating: false
    })
  )
  .case(
    actions.updateOrganization.failed,
    (state): Reducer => ({
      ...state,
      isOrganizationUpdating: false
    })
  )
  .case(
    actions.deleteOrganization.started,
    (state): Reducer => ({
      ...state,
      isOrganizationDeleting: true
    })
  )
  .case(
    actions.deleteOrganization.done,
    (state): Reducer => ({
      ...state,
      isOrganizationDeleting: false,
      organization: null
    })
  )
  .case(
    actions.deleteOrganization.failed,
    (state): Reducer => ({
      ...state,
      isOrganizationDeleting: false
    })
  )
  .case(
    actions.changeOrganizationOwner.started,
    (state): Reducer => ({
      ...state,
      isOrganizationOwnerChanging: true
    })
  )
  .case(
    actions.changeOrganizationOwner.done,
    (state): Reducer => ({
      ...state,
      isOrganizationOwnerChanging: false
    })
  )
  .case(
    actions.changeOrganizationOwner.failed,
    (state): Reducer => ({
      ...state,
      isOrganizationOwnerChanging: false
    })
  )
  .case(
    actions.getGroup.started,
    (state): Reducer => ({
      ...state,
      isGroupLoading: true
    })
  )
  .case(
    actions.getGroup.done,
    (state, payload): Reducer => ({
      ...state,
      group: payload.result,
      isGroupLoading: false
    })
  )
  .case(
    actions.getGroup.failed,
    (state): Reducer => ({
      ...state,
      isGroupLoading: false
    })
  )
  .case(
    actions.getGroups.started,
    (state): Reducer => ({
      ...state,
      areGroupsLoading: true
    })
  )
  .case(
    actions.getGroups.done,
    (state, payload): Reducer => ({
      ...state,
      groups: payload.result,
      areGroupsLoading: false
    })
  )
  .case(
    actions.getGroups.failed,
    (state): Reducer => ({
      ...state,
      areGroupsLoading: false
    })
  )
  .case(
    actions.createGroup.started,
    (state): Reducer => ({
      ...state,
      isGroupCreating: true
    })
  )
  .case(
    actions.createGroup.done,
    (state): Reducer => ({
      ...state,
      isGroupCreating: false
    })
  )
  .case(
    actions.createGroup.failed,
    (state): Reducer => ({
      ...state,
      isGroupCreating: false
    })
  )
  .case(
    actions.updateGroup.started,
    (state): Reducer => ({
      ...state,
      isGroupUpdating: true
    })
  )
  .case(
    actions.updateGroup.done,
    (state): Reducer => ({
      ...state,
      isGroupUpdating: false
    })
  )
  .case(
    actions.updateGroup.failed,
    (state): Reducer => ({
      ...state,
      isGroupUpdating: false
    })
  )
  .case(
    actions.deleteGroup.started,
    (state): Reducer => ({
      ...state,
      isGroupDeleting: true
    })
  )
  .case(
    actions.deleteGroup.done,
    (state): Reducer => ({
      ...state,
      isGroupDeleting: false,
      group: null
    })
  )
  .case(
    actions.deleteGroup.failed,
    (state): Reducer => ({
      ...state,
      isGroupDeleting: false
    })
  )
  .case(
    actions.leaveGroup.started,
    (state): Reducer => ({
      ...state,
      isGroupLeaving: true
    })
  )
  .case(
    actions.leaveGroup.done,
    (state): Reducer => ({
      ...state,
      isGroupLeaving: false,
      group: null
    })
  )
  .case(
    actions.leaveGroup.failed,
    (state): Reducer => ({
      ...state,
      isGroupLeaving: false
    })
  )
  .case(
    actions.getOrganizationUsers.started,
    (state): Reducer => ({
      ...state,
      areOrganizationUsersLoading: true
    })
  )
  .case(
    actions.getOrganizationUsers.done,
    (state, payload): Reducer => ({
      ...state,
      organizationUsers: payload.result,
      areOrganizationUsersLoading: false
    })
  )
  .case(
    actions.getOrganizationUsers.failed,
    (state): Reducer => ({
      ...state,
      areOrganizationUsersLoading: false
    })
  )
  .case(
    actions.getGroupUsers.started,
    (state): Reducer => ({
      ...state,
      areGroupUsersLoading: true
    })
  )
  .case(
    actions.getGroupUsers.done,
    (state, payload): Reducer => ({
      ...state,
      groupUsers: payload.result,
      areGroupUsersLoading: false
    })
  )
  .case(
    actions.getGroupUsers.failed,
    (state): Reducer => ({
      ...state,
      areGroupUsersLoading: false
    })
  )
  .case(
    actions.removeUserFromOrganization.started,
    (state, payload): Reducer => ({
      ...state,
      isUserRemovingFromOrganization: true,
      userIdToRemoveFromOrganization: payload.userId,
      userRemovalFromOrganizationError: null
    })
  )
  .case(
    actions.removeUserFromOrganization.done,
    (state): Reducer => ({
      ...state,
      isUserRemovingFromOrganization: false
    })
  )
  .case(
    actions.removeUserFromOrganization.failed,
    (state, payload): Reducer => ({
      ...state,
      isUserRemovingFromOrganization: false,
      userRemovalFromOrganizationError: (payload.error as Error).message
    })
  )
  .case(
    actions.removeUserFromGroup.started,
    (state): Reducer => ({
      ...state,
      isUserRemovingFromGroup: true
    })
  )
  .case(
    actions.removeUserFromGroup.done,
    (state): Reducer => ({
      ...state,
      isUserRemovingFromGroup: false
    })
  )
  .case(
    actions.removeUserFromGroup.failed,
    (state): Reducer => ({
      ...state,
      isUserRemovingFromGroup: false
    })
  )
  .case(
    actions.submitHubSpotForm.started,
    (state): Reducer => ({
      ...state,
      isHubspotFormSubmitting: true
    })
  )
  .case(
    actions.submitHubSpotForm.done,
    (state): Reducer => ({
      ...state,
      isHubspotFormSubmitting: false
    })
  )
  .case(
    actions.submitHubSpotForm.failed,
    (state): Reducer => ({
      ...state,
      isHubspotFormSubmitting: false
    })
  )
  .case(
    actions.getOrganizationInvitations.started,
    (state): Reducer => ({
      ...state,
      areOrganizationInvitationsLoading: true
    })
  )
  .case(
    actions.getOrganizationInvitations.done,
    (state, payload): Reducer => ({
      ...state,
      organizationInvitations: payload.result,
      areOrganizationInvitationsLoading: false
    })
  )
  .case(
    actions.getOrganizationInvitations.failed,
    (state): Reducer => ({
      ...state,
      areOrganizationInvitationsLoading: false
    })
  )
  .case(
    actions.getOrganizationInvitation.started,
    (state): Reducer => ({
      ...state,
      isOrganizationInvitationLoading: true
    })
  )
  .case(
    actions.getOrganizationInvitation.done,
    (state, payload): Reducer => ({
      ...state,
      organizationInvitation: payload.result,
      isOrganizationInvitationLoading: false
    })
  )
  .case(
    actions.getOrganizationInvitation.failed,
    (state): Reducer => ({
      ...state,
      isOrganizationInvitationLoading: false
    })
  )
  .case(
    actions.inviteUserToOrganization.started,
    (state): Reducer => ({
      ...state,
      isUserInvitingToOrganization: true
    })
  )
  .case(
    actions.inviteUserToOrganization.done,
    (state): Reducer => ({
      ...state,
      isUserInvitingToOrganization: false
    })
  )
  .case(
    actions.inviteUserToOrganization.failed,
    (state): Reducer => ({
      ...state,
      isUserInvitingToOrganization: false
    })
  )
  .case(
    actions.acceptOrganizationInvitation.started,
    (state): Reducer => ({
      ...state,
      isOrganizationInvitationAccepting: true
    })
  )
  .case(
    actions.acceptOrganizationInvitation.done,
    (state): Reducer => ({
      ...state,
      isOrganizationInvitationAccepting: false
    })
  )
  .case(
    actions.acceptOrganizationInvitation.failed,
    (state, payload): Reducer => ({
      ...state,
      isOrganizationInvitationAccepting: false,
      organizationInvitationAcceptanceError: (payload.error as Error).message
    })
  )
  .case(
    actions.deleteOrganizationInvitation.started,
    (state): Reducer => ({
      ...state,
      isOrganizationInvitationDeleting: true
    })
  )
  .case(
    actions.deleteOrganizationInvitation.done,
    (state): Reducer => ({
      ...state,
      isOrganizationInvitationDeleting: false
    })
  )
  .case(
    actions.deleteOrganizationInvitation.failed,
    (state): Reducer => ({
      ...state,
      isOrganizationInvitationDeleting: false
    })
  )

  .case(
    actions.getGroupInvitations.started,
    (state): Reducer => ({
      ...state,
      areGroupInvitationsLoading: true
    })
  )
  .case(
    actions.getGroupInvitations.done,
    (state, payload): Reducer => ({
      ...state,
      groupInvitations: payload.result,
      areGroupInvitationsLoading: false
    })
  )
  .case(
    actions.getGroupInvitations.failed,
    (state): Reducer => ({
      ...state,
      areGroupInvitationsLoading: false
    })
  )
  .case(
    actions.getGroupInvitation.started,
    (state): Reducer => ({
      ...state,
      isGroupInvitationLoading: true
    })
  )
  .case(
    actions.getGroupInvitation.done,
    (state, payload): Reducer => ({
      ...state,
      groupInvitation: payload.result,
      isGroupInvitationLoading: false
    })
  )
  .case(
    actions.getGroupInvitation.failed,
    (state): Reducer => ({
      ...state,
      isGroupInvitationLoading: false
    })
  )
  .case(
    actions.inviteUserToGroup.started,
    (state): Reducer => ({
      ...state,
      isUserInvitingToGroup: true
    })
  )
  .case(
    actions.inviteUserToGroup.done,
    (state): Reducer => ({
      ...state,
      isUserInvitingToGroup: false
    })
  )
  .case(
    actions.inviteUserToGroup.failed,
    (state): Reducer => ({
      ...state,
      isUserInvitingToGroup: false
    })
  )
  .case(
    actions.acceptGroupInvitation.started,
    (state): Reducer => ({
      ...state,
      isGroupInvitationAccepting: true
    })
  )
  .case(
    actions.acceptGroupInvitation.done,
    (state): Reducer => ({
      ...state,
      isGroupInvitationAccepting: false
    })
  )
  .case(
    actions.acceptGroupInvitation.failed,
    (state, payload): Reducer => ({
      ...state,
      isGroupInvitationAccepting: false,
      groupInvitationAcceptanceError: (payload.error as Error).message
    })
  )
  .case(
    actions.deleteGroupInvitation.started,
    (state): Reducer => ({
      ...state,
      isGroupInvitationDeleting: true
    })
  )
  .case(
    actions.deleteGroupInvitation.done,
    (state): Reducer => ({
      ...state,
      isGroupInvitationDeleting: false
    })
  )
  .case(
    actions.deleteGroupInvitation.failed,
    (state): Reducer => ({
      ...state,
      isGroupInvitationDeleting: false
    })
  )

  .case(
    actions.registerNewUser.started,
    (state): Reducer => ({
      ...state,
      isNewUserRegistering: true
    })
  )
  .case(
    actions.registerNewUser.done,
    (state, payload): Reducer => ({
      ...state,
      isNewUserRegistering: false
    })
  )
  .case(
    actions.registerNewUser.failed,
    (state): Reducer => ({
      ...state,
      isNewUserRegistering: false
    })
  )
  .case(actions.clear, (): Reducer => initialState);
