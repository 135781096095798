import { AxiosResponse } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { appConfig } from "../../appConfig";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import {
  AcceptGroupInvitationParams,
  AcceptOrganizationInvitationParams,
  ChangeOrganizationOwnerParams,
  CreateGroupParams,
  CreateGroupResponse,
  CreateOrganizationParams,
  CreateOrganizationResponse,
  DeleteGroupInvitationParams,
  DeleteGroupParams,
  DeleteOrganizationInvitationParams,
  DeleteOrganizationParams,
  GetGroupInvitationParams,
  GetGroupInvitationsParams,
  GetGroupInvitationsResponse,
  GetGroupParams,
  GetGroupResponse,
  GetGroupsParams,
  GetGroupsResponse,
  GetGroupUsersParams,
  GetGroupUsersResponse,
  GetOrganizationInvitationParams,
  GetOrganizationInvitationResponse,
  GetOrganizationInvitationsParams,
  GetOrganizationInvitationsResponse,
  GetOrganizationParams,
  GetOrganizationResponse,
  GetOrganizationsParams,
  GetOrganizationsResponse,
  GetOrganizationUsersParams,
  GetOrganizationUsersResponse,
  INVITATION_TYPES,
  InviteUserToGroupParams,
  InviteUserToOrganizationParams,
  InviteUserToOrganizationResponse,
  LeaveGroupParams,
  LeaveOrganizationParams,
  RegisterNewUserParams,
  RegisterNewUserResponse,
  RemoveUserFromGroupParams,
  RemoveUserFromOrganizationParams,
  SubmitHubSpotFormParams,
  UpdateGroupParams,
  UpdateGroupResponse,
  UpdateOrganizationParams,
  UpdateOrganizationResponse
} from "./types";

const serviceApiPath = `gotham-enterprises/method/`;

export function* getOrganizationSaga(
  action: Action<GetOrganizationParams>
): SagaIterator<void> {
  try {
    const response: AxiosResponse<GetOrganizationResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}orgs/${action.payload.id}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getOrganization.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getOrganization.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get organization data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getOrganizationsSaga(
  action: Action<GetOrganizationsParams>
): SagaIterator<void> {
  try {
    const response: AxiosResponse<GetOrganizationsResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}orgs`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getOrganizations.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getOrganizations.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get organizations data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createOrganizationSaga(
  action: Action<CreateOrganizationParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the organization...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const response: AxiosResponse<CreateOrganizationResponse> = yield call(
      axiosInstance.post,
      `${serviceApiPath}orgs/${action.payload.data.name}`
    );
    yield put(
      actions.createOrganization.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Organization has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createOrganization.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create organization",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateOrganizationSaga(
  action: Action<UpdateOrganizationParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the organization...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const response: AxiosResponse<UpdateOrganizationResponse> = yield call(
      axiosInstance.put,
      `${serviceApiPath}orgs/${action.payload.id}/${action.payload.data.name}`
    );
    yield put(
      actions.updateOrganization.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Organization has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateOrganization.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update organization",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteOrganizationSaga(
  action: Action<DeleteOrganizationParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Deleting the organization...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    yield call(
      axiosInstance.delete,
      `${serviceApiPath}orgs/${action.payload.id}`
    );
    yield put(
      actions.deleteOrganization.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Organization has been successfully deleted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteOrganization.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to delete organization",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* leaveOrganizationSaga(
  action: Action<LeaveOrganizationParams>
): SagaIterator<void> {
  try {
    const { organizationId } = action.payload;
    yield put(
      notificationsActions.showNotification({
        title: "Leaving the organization...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    yield call(
      axiosInstance.post,
      `${serviceApiPath}orgs/${organizationId}/leave`
    );
    yield put(
      actions.leaveOrganization.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "You have successfully left the organization.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.leaveOrganization.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to leave organization",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getGroupSaga(
  action: Action<GetGroupParams>
): SagaIterator<void> {
  try {
    const { organizationId, groupId } = action.payload;
    const response: AxiosResponse<GetGroupResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}orgs/${organizationId}/groups/${groupId}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getGroup.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getGroup.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get group",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getGroupsSaga(
  action: Action<GetGroupsParams>
): SagaIterator<void> {
  try {
    const response: AxiosResponse<GetGroupsResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}orgs/${action.payload.organizationId}/groups`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getGroups.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getGroups.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get groups",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createGroupSaga(
  action: Action<CreateGroupParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the group...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { organizationId, data } = action.payload;
    const response: AxiosResponse<CreateGroupResponse> = yield call(
      axiosInstance.post,
      `${serviceApiPath}orgs/${organizationId}/groups/${data.name}`
    );
    yield put(
      actions.createGroup.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Group has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(actions.createGroup.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create group",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateGroupSaga(
  action: Action<UpdateGroupParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the group...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { organizationId, id, data } = action.payload;
    const response: AxiosResponse<UpdateGroupResponse> = yield call(
      axiosInstance.put,
      `${serviceApiPath}orgs/${organizationId}/groups/${id}`,
      data
    );
    yield put(
      actions.updateGroup.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Group has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(actions.updateGroup.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update group",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteGroupSaga(
  action: Action<DeleteGroupParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Deleting the group...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { organizationId, id } = action.payload;
    yield call(
      axiosInstance.delete,
      `${serviceApiPath}orgs/${organizationId}/groups/${id}`
    );
    yield put(
      actions.deleteGroup.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Group has been successfully deleted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(actions.deleteGroup.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to delete group",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* leaveGroupSaga(
  action: Action<LeaveGroupParams>
): SagaIterator<void> {
  try {
    const { organizationId, id } = action.payload;
    yield put(
      notificationsActions.showNotification({
        title: "Leaving the group...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    yield call(
      axiosInstance.post,
      `${serviceApiPath}orgs/${organizationId}/groups/${id}/leave`
    );
    yield put(
      actions.leaveGroup.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "You have successfully left the group.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(actions.leaveGroup.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to leave group",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getOrganizationUsersSaga(
  action: Action<GetOrganizationUsersParams>
): SagaIterator<void> {
  try {
    const response: AxiosResponse<GetOrganizationUsersResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}orgs/${action.payload.organizationId}/users`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getOrganizationUsers.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getOrganizationUsers.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get organization users",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getGroupUsersSaga(
  action: Action<GetGroupUsersParams>
): SagaIterator<void> {
  try {
    const { organizationId, groupId } = action.payload;
    const response: AxiosResponse<GetGroupUsersResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}orgs/${organizationId}/groups/${groupId}/users`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getGroupUsers.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getGroupUsers.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get group users",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* removeUserFromOrganizationSaga(
  action: Action<RemoveUserFromOrganizationParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Removing user from the organization administrators...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { organizationId, userId } = action.payload;
    yield call(
      axiosInstance.delete,
      `${serviceApiPath}orgs/${organizationId}/users/${userId}`
    );
    yield put(
      actions.removeUserFromOrganization.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title:
          "User has been successfully removed from the organization administrators.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.removeUserFromOrganization.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to remove user from the organization",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* removeUserFromGroupSaga(
  action: Action<RemoveUserFromGroupParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Removing user from the group...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { organizationId, groupId, userId } = action.payload;
    yield call(
      axiosInstance.delete,
      `${serviceApiPath}orgs/${organizationId}/groups/${groupId}/users/${userId}`
    );
    yield put(
      actions.removeUserFromGroup.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "User has been successfully removed from the group.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.removeUserFromGroup.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to remove user from the group",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* submitHubSpotFormSaga(
  action: Action<SubmitHubSpotFormParams>
): SagaIterator<void> {
  try {
    yield call(axiosInstance.post, `${serviceApiPath}users/submit-form`, null, {
      params: {
        hutk: action.payload.hutk
      }
    });
    yield put(
      actions.submitHubSpotForm.done({
        params: action.payload
      })
    );
  } catch (e) {
    yield put(
      actions.submitHubSpotForm.failed({ params: action.payload, error: e })
    );
  }
}

export function* getOrganizationInvitationsSaga(
  action: Action<GetOrganizationInvitationsParams>
): SagaIterator<void> {
  try {
    const response: AxiosResponse<GetOrganizationInvitationsResponse> =
      yield call(
        axiosInstance.get,
        `${serviceApiPath}orgs/${action.payload.organizationId}/invitations`
      );
    yield put(
      actions.getOrganizationInvitations.done({
        params: action.payload,
        result: response.data
      })
    );
  } catch (e) {
    yield put(
      actions.getOrganizationInvitations.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get organization invitations",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getOrganizationInvitationSaga(
  action: Action<GetOrganizationInvitationParams>
): SagaIterator<void> {
  try {
    const { organizationId, invitationId } = action.payload;
    const response: AxiosResponse<GetOrganizationInvitationResponse> =
      yield call(
        axiosInstance.get,
        `${serviceApiPath}orgs/${organizationId}/invitations/${invitationId}`
      );
    yield put(
      actions.getOrganizationInvitation.done({
        params: action.payload,
        result: response.data
      })
    );
  } catch (e) {
    yield put(
      actions.getOrganizationInvitation.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get organization invitation",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* inviteUserToOrganizationSaga(
  action: Action<InviteUserToOrganizationParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending the invitation...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { organizationId, email } = action.payload;
    const response: AxiosResponse<InviteUserToOrganizationResponse> =
      yield call(
        axiosInstance.post,
        `${serviceApiPath}orgs/${organizationId}/invitations`,
        {
          email,
          type: INVITATION_TYPES.ORGANIZATION_ADMINISTRATOR,
          domain: appConfig.domain
        }
      );
    yield put(
      actions.inviteUserToOrganization.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Invitation has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.inviteUserToOrganization.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send the invitation",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* acceptOrganizationInvitationSaga(
  action: Action<AcceptOrganizationInvitationParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Accepting the invitation...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { organizationId, invitationId } = action.payload;
    yield call(
      axiosInstance.post,
      `${serviceApiPath}orgs/${organizationId}/invitations/${invitationId}`
    );
    yield put(
      actions.acceptOrganizationInvitation.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Invitation has been successfully accepted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.acceptOrganizationInvitation.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to accept the invitation",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteOrganizationInvitationSaga(
  action: Action<DeleteOrganizationInvitationParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Deleting the invitation...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { organizationId, invitationId } = action.payload;
    yield call(
      axiosInstance.delete,
      `${serviceApiPath}orgs/${organizationId}/invitations/${invitationId}`
    );
    yield put(
      actions.deleteOrganizationInvitation.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Invitation has been successfully deleted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteOrganizationInvitation.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to delete the invitation",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getGroupInvitationsSaga(
  action: Action<GetGroupInvitationsParams>
): SagaIterator<void> {
  try {
    const { organizationId, groupId } = action.payload;
    const response: AxiosResponse<GetGroupInvitationsResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}orgs/${organizationId}/groups/${groupId}/invitations`
    );
    yield put(
      actions.getGroupInvitations.done({
        params: action.payload,
        result: response.data
      })
    );
  } catch (e) {
    yield put(
      actions.getGroupInvitations.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get group invitations",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getGroupInvitationSaga(
  action: Action<GetGroupInvitationParams>
): SagaIterator<void> {
  try {
    const { organizationId, groupId, invitationId } = action.payload;
    const response: AxiosResponse<GetOrganizationInvitationResponse> =
      yield call(
        axiosInstance.get,
        `${serviceApiPath}orgs/${organizationId}/groups/${groupId}/invitations/${invitationId}`
      );
    yield put(
      actions.getGroupInvitation.done({
        params: action.payload,
        result: response.data
      })
    );
  } catch (e) {
    yield put(
      actions.getGroupInvitation.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get group invitation",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* inviteUserToGroupSaga(
  action: Action<InviteUserToGroupParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending the invitation...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { organizationId, groupId, email } = action.payload;
    const response: AxiosResponse<InviteUserToOrganizationResponse> =
      yield call(
        axiosInstance.post,
        `${serviceApiPath}orgs/${organizationId}/groups/${groupId}/invitations`,
        {
          email,
          type: INVITATION_TYPES.GROUP_MEMBER,
          domain: appConfig.domain
        }
      );
    yield put(
      actions.inviteUserToGroup.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Invitation has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.inviteUserToGroup.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send the invitation",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* acceptGroupInvitationSaga(
  action: Action<AcceptGroupInvitationParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Accepting the invitation...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { organizationId, groupId, invitationId } = action.payload;
    yield call(
      axiosInstance.post,
      `${serviceApiPath}orgs/${organizationId}/groups/${groupId}/invitations/${invitationId}`
    );
    yield put(
      actions.acceptGroupInvitation.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Invitation has been successfully accepted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.acceptGroupInvitation.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to accept the invitation",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteGroupInvitationSaga(
  action: Action<DeleteGroupInvitationParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Deleting the invitation...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { organizationId, groupId, invitationId } = action.payload;
    yield call(
      axiosInstance.delete,
      `${serviceApiPath}orgs/${organizationId}/groups/${groupId}/invitations/${invitationId}`
    );
    yield put(
      actions.deleteGroupInvitation.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Invitation has been successfully deleted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteGroupInvitation.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to delete the invitation",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* changeOrganizationOwnerSaga(
  action: Action<ChangeOrganizationOwnerParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending an invitation to become the organization owner...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { organizationId, email } = action.payload;
    yield call(
      axiosInstance.post,
      `${serviceApiPath}orgs/${organizationId}/invitations`,
      {
        email,
        type: INVITATION_TYPES.ORGANIZATION_OWNER,
        domain: appConfig.domain
      }
    );
    yield put(
      actions.changeOrganizationOwner.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title:
          "The invitation to become the organization owner has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.changeOrganizationOwner.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send invitation to become the organization owner.",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* registerNewUserUserSaga(
  action: Action<RegisterNewUserParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Registering new user...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { data } = action.payload;
    const response: AxiosResponse<RegisterNewUserResponse> = yield call(
      axiosInstance.post,
      `${serviceApiPath}users`,
      data
    );
    yield put(
      actions.registerNewUser.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "User has been successfully registered",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.registerNewUser.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to register user",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLeading(actions.getOrganization.started, getOrganizationSaga),
    takeLeading(actions.getOrganizations.started, getOrganizationsSaga),
    takeEvery(actions.createOrganization.started, createOrganizationSaga),
    takeEvery(actions.updateOrganization.started, updateOrganizationSaga),
    takeEvery(actions.deleteOrganization.started, deleteOrganizationSaga),
    takeEvery(actions.leaveOrganization.started, leaveOrganizationSaga),
    takeLeading(actions.getGroup.started, getGroupSaga),
    takeLeading(actions.getGroups.started, getGroupsSaga),
    takeEvery(actions.createGroup.started, createGroupSaga),
    takeEvery(actions.updateGroup.started, updateGroupSaga),
    takeEvery(actions.deleteGroup.started, deleteGroupSaga),
    takeEvery(actions.leaveGroup.started, leaveGroupSaga),
    takeLeading(actions.getOrganizationUsers.started, getOrganizationUsersSaga),
    takeLeading(actions.getGroupUsers.started, getGroupUsersSaga),
    takeEvery(actions.removeUserFromGroup.started, removeUserFromGroupSaga),
    takeEvery(
      actions.removeUserFromOrganization.started,
      removeUserFromOrganizationSaga
    ),
    takeEvery(actions.submitHubSpotForm.started, submitHubSpotFormSaga),
    takeLeading(
      actions.getOrganizationInvitations.started,
      getOrganizationInvitationsSaga
    ),
    takeLeading(
      actions.getOrganizationInvitation.started,
      getOrganizationInvitationSaga
    ),
    takeEvery(
      actions.inviteUserToOrganization.started,
      inviteUserToOrganizationSaga
    ),
    takeEvery(
      actions.acceptOrganizationInvitation.started,
      acceptOrganizationInvitationSaga
    ),
    takeEvery(
      actions.deleteOrganizationInvitation.started,
      deleteOrganizationInvitationSaga
    ),
    takeEvery(
      actions.changeOrganizationOwner.started,
      changeOrganizationOwnerSaga
    ),
    takeLeading(actions.getGroupInvitations.started, getGroupInvitationsSaga),
    takeLeading(actions.getGroupInvitation.started, getGroupInvitationSaga),
    takeEvery(actions.inviteUserToGroup.started, inviteUserToGroupSaga),
    takeEvery(actions.acceptGroupInvitation.started, acceptGroupInvitationSaga),
    takeEvery(actions.deleteGroupInvitation.started, deleteGroupInvitationSaga),
    takeEvery(actions.registerNewUser.started, registerNewUserUserSaga)
  ]);
}
