import { ROLE_LABELS } from "../../constants";
import { RootReducer } from "../../reducers";
import {
  Group,
  Invitation,
  INVITATION_TYPES,
  Organization,
  TableOrganization,
  TableUser
} from "./types";

const sortUsersByEmail = (a: TableUser, b: TableUser): number => {
  if (a.email < b.email) {
    return -1;
  }

  if (a.email > b.email) {
    return 1;
  }

  return 0;
};

export const tableOrganizationsSelector = (
  state: RootReducer
): TableOrganization[] | null =>
  state.enterprises.organizations?.map((organization) => ({
    ...organization,
    role: ROLE_LABELS[organization.role]
  })) || null;

export const areOrganizationsLoadingSelector = (state: RootReducer): boolean =>
  state.enterprises.areOrganizationsLoading;

export const organizationSelector = (state: RootReducer): Organization | null =>
  state.enterprises.organization;

export const isOrganizationLoadingSelector = (state: RootReducer): boolean =>
  state.enterprises.isOrganizationLoading;

export const isOrganizationCreatingSelector = (state: RootReducer): boolean =>
  state.enterprises.isOrganizationCreating;

export const isOrganizationUpdatingSelector = (state: RootReducer): boolean =>
  state.enterprises.isOrganizationUpdating;

export const isOrganizationDeletingSelector = (state: RootReducer): boolean =>
  state.enterprises.isOrganizationDeleting;

export const areProjectsLoadingSelector = (state: RootReducer): boolean =>
  state.enterprises.areOrganizationsLoading;

export const groupsSelector = (state: RootReducer): Group[] | null =>
  state.enterprises.groups;

export const areGroupsLoadingSelector = (state: RootReducer): boolean =>
  state.enterprises.areGroupsLoading;

export const groupSelector = (state: RootReducer): Group | null =>
  state.enterprises.group;

export const isGroupLoadingSelector = (state: RootReducer): boolean =>
  state.enterprises.isGroupLoading;

export const isGroupUpdatingSelector = (state: RootReducer): boolean =>
  state.enterprises.isGroupUpdating;

export const tableGroupUsersSelector = (
  state: RootReducer
): TableUser[] | null =>
  state.enterprises.groupUsers && state.enterprises.groupInvitations
    ? [
        ...state.enterprises.groupUsers.map((user) => ({
          id: user.id,
          email: user.email,
          formattedEmail: user.email,
          role: ROLE_LABELS[user.role],
          fullName: user.full_name
        })),
        ...state.enterprises.groupInvitations.map((invitation) => ({
          id: invitation.inviteeEmail,
          email: invitation.inviteeEmail,
          formattedEmail: `${invitation.inviteeEmail} (pending invitation)`,
          invitationId: invitation.id,
          invitationType: invitation.type,
          role: "",
          fullName: ""
        }))
      ].sort(sortUsersByEmail)
    : null;

export const areGroupUsersLoadingSelector = (state: RootReducer): boolean =>
  state.enterprises.areGroupUsersLoading;

export const tableOrganizationUsersSelector = (
  state: RootReducer
): TableUser[] | null =>
  state.enterprises.organizationUsers &&
  state.enterprises.organizationInvitations
    ? [
        ...state.enterprises.organizationUsers.map((user) => {
          const ownershipTransferInvitation =
            state.enterprises.organizationInvitations?.find(
              (invitation) => invitation.inviteeEmail === user.email
            );
          return {
            id: user.id,
            email: user.email,
            formattedEmail: `${user.email}${
              ownershipTransferInvitation ? " (pending ownership transfer)" : ""
            }`,
            invitationId: ownershipTransferInvitation?.id,
            invitationType: ownershipTransferInvitation?.type,
            role: ROLE_LABELS[user.role],
            fullName: user.full_name
          };
        }),
        ...state.enterprises.organizationInvitations
          .filter(
            (invitation) =>
              invitation.type === INVITATION_TYPES.ORGANIZATION_ADMINISTRATOR
          )
          .map((invitation) => ({
            id: invitation.inviteeEmail,
            email: invitation.inviteeEmail,
            formattedEmail: `${invitation.inviteeEmail} (pending invitation)`,
            invitationId: invitation.id,
            invitationType: invitation.type,
            role: "",
            fullName: ""
          }))
      ].sort(sortUsersByEmail)
    : null;

export const areOrganizationUsersLoadingSelector = (
  state: RootReducer
): boolean => state.enterprises.areOrganizationUsersLoading;

export const isUserRemovingFromOrganizationSelector = (
  state: RootReducer
): boolean => state.enterprises.isUserRemovingFromOrganization;

export const userIdToRemoveFromOrganizationSelector = (
  state: RootReducer
): string | null => state.enterprises.userIdToRemoveFromOrganization;

export const userRemovalFromOrganizationErrorSelector = (
  state: RootReducer
): string | null => state.enterprises.userRemovalFromOrganizationError;

export const isOrganizationOwnerChangingSelector = (
  state: RootReducer
): boolean => state.enterprises.isOrganizationOwnerChanging;

export const isUserRemovingFromGroupSelector = (state: RootReducer): boolean =>
  state.enterprises.isUserRemovingFromGroup;

export const isGroupCreatingSelector = (state: RootReducer): boolean =>
  state.enterprises.isGroupCreating;

export const isGroupDeletingSelector = (state: RootReducer): boolean =>
  state.enterprises.isGroupDeleting;

export const isGroupLeavingSelector = (state: RootReducer): boolean =>
  state.enterprises.isGroupLeaving;

export const areOrganizationInvitationsLoadingSelector = (
  state: RootReducer
): boolean => state.enterprises.areOrganizationInvitationsLoading;

export const isUserInvitingToOrganizationSelector = (
  state: RootReducer
): boolean => state.enterprises.isUserInvitingToOrganization;

export const areGroupInvitationsLoadingSelector = (
  state: RootReducer
): boolean => state.enterprises.areGroupInvitationsLoading;

export const isUserInvitingToGroupSelector = (state: RootReducer): boolean =>
  state.enterprises.isUserInvitingToGroup;

export const invitationSelector = (state: RootReducer): Invitation | null =>
  state.enterprises.organizationInvitation || state.enterprises.groupInvitation;

export const isInvitationLoadingSelector = (state: RootReducer): boolean =>
  state.enterprises.isOrganizationInvitationLoading ||
  state.enterprises.isGroupInvitationLoading;

export const isInvitationAcceptingSelector = (state: RootReducer): boolean =>
  state.enterprises.isOrganizationInvitationAccepting ||
  state.enterprises.isGroupInvitationAccepting;

export const isOrganizationInvitationDeletingSelector = (
  state: RootReducer
): boolean => state.enterprises.isOrganizationInvitationDeleting;

export const isGroupInvitationDeletingSelector = (
  state: RootReducer
): boolean => state.enterprises.isGroupInvitationDeleting;

export const invitationAcceptanceErrorSelector = (
  state: RootReducer
): string | null =>
  state.enterprises.organizationInvitationAcceptanceError ||
  state.enterprises.groupInvitationAcceptanceError;

export const isNewUserRegisteringSelector = (state: RootReducer): boolean =>
  state.enterprises.isNewUserRegistering;
