import { formatRegion } from "utils/formatRegion";
import { RootReducer } from "../../reducers";
import { CLIUser, Project, TableProject } from "./types";
import { createSelector } from "reselect";

export const projectSelector = (state: RootReducer): Project | null =>
  state.projects.project;

export const isProjectLoadingSelector = (state: RootReducer): boolean =>
  state.projects.isProjectLoading;

export const isProjectCreatingSelector = (state: RootReducer): boolean =>
  state.projects.isProjectCreating;

export const isProjectUpdatingSelector = (state: RootReducer): boolean =>
  state.projects.isProjectUpdating;

export const isProjectDeletingSelector = (state: RootReducer): boolean =>
  state.projects.isProjectDeleting;

// export const organizationProjectsSelector = (
//   state: RootReducer
// ): TableProject[] | null =>
//   state.projects.organizationProjects?.map((project) => ({
//     ...project,
//     region: formatRegion(project.region)
//   })) || null;

const selectOrganizationProjects = (state: RootReducer) =>
  state.projects.organizationProjects;

export const organizationProjectsSelector = createSelector(
  [selectOrganizationProjects],
  (organizationProjects) => {
    if (!Array.isArray(organizationProjects)) {
      return null;
    }

    return organizationProjects.map((project) => ({
      ...project,
      region: formatRegion(project.region)
    }));
  }
);

export const areOrganizationProjectsLoadingSelector = (
  state: RootReducer
): boolean => state.projects.areOrganizationProjectsLoading;

export const cliUsersSelector = (state: RootReducer): CLIUser[] | null =>
  state.projects.cliUsers;

export const isCLIUserCreatingSelector = (state: RootReducer): boolean =>
  state.projects.isCLIUserCreating;

export const isCLIUserUpdatingSelector = (state: RootReducer): boolean =>
  state.projects.isCLIUserUpdating;

export const isCLIUserDeletingSelector = (state: RootReducer): boolean =>
  state.projects.isCLIUserDeleting;

export const areCLIUsersLoadingSelector = (state: RootReducer): boolean =>
  state.projects.areCLIUsersLoading;

export const isGroupToProjectAssigningSelector = (
  state: RootReducer
): boolean => state.projects.isGroupToProjectAssigning;

export const isGroupFromProjectRemovingSelector = (
  state: RootReducer
): boolean => state.projects.isGroupFromProjectRemoving;
