import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { match, pathToRegexp } from "path-to-regexp";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import OrganizationIcon from "@mui/icons-material/Domain";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import ShieldIcon from "@mui/icons-material/Security";
import ServerIcon from "@mui/icons-material/Storage";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { ClusterIcon } from "components/common/icons/ClusterIcon";
import { DatabaseIcon } from "components/common/icons/DatabaseIcon";
import { NetworkIcon } from "components/common/icons/NetworkIcon";
import { StorageIcon } from "components/common/icons/StorageIcon";
import { Logo } from "components/common/Logo";
import { Snackbar } from "components/common/Snackbar";
import { Content } from "components/Content";
import { organizationSelector } from "modules/enterprises/selectors";
import { ROLES } from "modules/enterprises/types";
import * as notificationsActions from "modules/notifications/actions";
import { notificationSelector } from "modules/notifications/selectors";
import { projectSelector } from "modules/projects/selectors";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useDarkMode } from "usehooks-ts";
import { useDarkMode } from "../../hooks/useDarkMode";
import {
  generatePath,
  Link,
  useLocation,
  useMatch,
  useNavigate
} from "react-router-dom";
import { appConfig } from "../../appConfig";
import { ROUTES } from "../../constants";
import * as s from "./styles";
import { NavigationMenuItem } from "./types";
import { ListItemButton } from "@mui/material";

const inventoryMenuItems: NavigationMenuItem[] = [
  {
    route: ROUTES.VIRTUAL_MACHINES,
    icon: <ServerIcon />,
    label: "Virtual machines"
  },
  {
    route: ROUTES.CLUSTERS,
    icon: <ClusterIcon />,
    label: "Clusters"
  },

  {
    route: ROUTES.DATABASES,
    icon: <DatabaseIcon />,
    label: "Databases"
  },
  {
    route: ROUTES.NETWORKING,
    icon: <NetworkIcon />,
    label: "Networking"
  },
  {
    route: ROUTES.SECURITY,
    icon: <ShieldIcon />,
    label: "Security"
  },
  {
    route: ROUTES.STORAGE,
    icon: <StorageIcon />,
    label: "Storage"
  },
  {
    route: ROUTES.BACKUPS_WORKLOADS,
    icon: <CloudDownloadIcon />,
    label: "Backups"
  }
  // {
  //   route: ROUTES.NLP,
  //   icon: <NLPIcon />,
  //   label: "NLP (alpha)"
  // }
];

const ROUTES_WITHOUT_MENU = [
  ROUTES.ORGANIZATION_INVITATION_ACCEPTANCE,
  ROUTES.GROUP_INVITATION_ACCEPTANCE
];

export const Container = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isDarkMode, toggle } = useDarkMode();
  const notification = useSelector(notificationSelector);
  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState<boolean>(false);

  const handleCloseSnackbar = useCallback(() => {
    setIsSnackbarVisible(false);
  }, []);

  const handleSnackbarExited = useCallback(() => {
    dispatch(notificationsActions.clear());
  }, [dispatch]);

  useEffect(() => {
    setIsSnackbarVisible(Boolean(notification));
  }, [notification]);

  // const currentRoute =
  //   Object.values(ROUTES).find((route) => {
  //     return pathToRegexp(route).exec(location.pathname);
  //   }) || "/";

  const currentRoute =
    Object.values(ROUTES).find((route) => {
      const matcher = match(route, { decode: decodeURIComponent });
      return matcher(location.pathname) !== false;
    }) || "/";

  const routeParams = useMatch(currentRoute)?.params;

  const isMenuHidden = currentRoute
    ? ROUTES_WITHOUT_MENU.includes(currentRoute)
    : false;

  const itemsToSkip: string[] = [];

  if (!appConfig.isDbaasEnabled) {
    itemsToSkip.push("Databases");
  }

  if (!appConfig.isBackupsEnabled) {
    itemsToSkip.push("Backups");
  }

  const renderMenuItems = (menuItems: NavigationMenuItem[]) =>
    menuItems.map((menuItem) =>
      !itemsToSkip.includes(menuItem.label) ? (
        <ListItemButton
          sx={{ padding: "0" }}
          key={menuItem.route}
          selected={currentRoute?.includes(menuItem.route)}
          data-testid={"container-drawer-menulist-item"}
          role={"listitem"}
        >
          <s.Link to={generatePath(menuItem.route, routeParams)}>
            <s.IconContainer>{menuItem.icon}</s.IconContainer>
            <Typography>{menuItem.label}</Typography>
          </s.Link>
        </ListItemButton>
      ) : null
    );

  const renderOrganizationMenuItems = () => (
    <>
      <ListItemButton
        sx={{ padding: "0" }}
        selected={ROUTES.ORGANIZATION === currentRoute}
        data-testid={"container-drawer-menulist-item"}
        role={"listitem"}
      >
        <s.Link to={generatePath(ROUTES.ORGANIZATION, routeParams)}>
          <s.IconContainer>
            <DashboardIcon />
          </s.IconContainer>
          <Typography>Projects</Typography>
        </s.Link>
      </ListItemButton>
      <ListItemButton
        sx={{ padding: "0" }}
        selected={currentRoute?.includes(ROUTES.GROUPS)}
        disabled={!organization}
        data-testid={"container-drawer-menulist-item"}
        role={"listitem"}
      >
        <s.Link to={generatePath(ROUTES.GROUPS, routeParams)}>
          <s.IconContainer>
            <GroupIcon />
          </s.IconContainer>
          <Typography>Groups</Typography>
        </s.Link>
      </ListItemButton>
      <ListItemButton
        sx={{ padding: "0" }}
        selected={currentRoute?.includes(ROUTES.ADMINISTRATORS)}
        disabled={
          !organization ||
          ![ROLES.OWNER, ROLES.ADMIN].includes(organization.role)
        }
        data-testid={"container-drawer-menulist-item"}
        role={"listitem"}
      >
        <s.Link to={generatePath(ROUTES.ADMINISTRATORS, routeParams)}>
          <s.IconContainer>
            <PersonIcon />
          </s.IconContainer>
          <Typography>Administrators</Typography>
        </s.Link>
      </ListItemButton>
      <ListItemButton
        sx={{ padding: "0" }}
        selected={currentRoute?.includes(ROUTES.BILLING)}
        disabled={
          !organization ||
          ![ROLES.OWNER, ROLES.ADMIN].includes(organization.role)
        }
        data-testid={"container-drawer-menulist-item"}
        role={"listitem"}
      >
        <s.Link to={generatePath(ROUTES.BILLING, routeParams)}>
          <s.IconContainer>
            <AccountBalanceIcon />
          </s.IconContainer>
          <Typography>Billing</Typography>
        </s.Link>
      </ListItemButton>
    </>
  );

  return (
    <s.Container>
      <s.Drawer variant={"permanent"} open={true}>
        <s.DrawerHeader>
          <Link to={ROUTES.ROOT}>
            {/* <s.LogoContainer>
              {appConfig.theme.logoURL ? (
                <s.LogoImage
                  src={appConfig.theme.logoURL}
                  alt={appConfig.theme.logoDescription}
                />
              ) : (
                <Logo height={48} />
              )}
            </s.LogoContainer> */}
            <s.LogoContainer>
              {appConfig.theme.logoURL ? (
                appConfig.theme.isDarkModeEnabled &&
                isDarkMode &&
                appConfig.theme.darkModeLogoURL ? (
                  <s.LogoImage
                    src={appConfig.theme.darkModeLogoURL}
                    alt={appConfig.theme.logoDescription}
                  />
                ) : (
                  <s.LogoImage
                    src={appConfig.theme.logoURL}
                    alt={appConfig.theme.logoDescription}
                  />
                )
              ) : (
                <Logo height={48} />
              )}
            </s.LogoContainer>
          </Link>
        </s.DrawerHeader>
        <s.DrawerMenuList>
          <ListItemButton
            sx={{ padding: "0" }}
            selected={ROUTES.ORGANIZATIONS === currentRoute}
            data-testid={"container-drawer-menulist-item"}
            role={"listitem"}
          >
            <s.Link to={generatePath(ROUTES.ORGANIZATIONS, routeParams)}>
              <s.IconContainer>
                <OrganizationIcon />
              </s.IconContainer>
              <Typography>Organizations</Typography>
            </s.Link>
          </ListItemButton>
          {!isMenuHidden && routeParams?.organizationId && (
            <>
              <s.SectionName>
                <s.SectionNameText title={organization?.name}>
                  {organization?.name || ""}
                </s.SectionNameText>
              </s.SectionName>
              {renderOrganizationMenuItems()}
            </>
          )}
          {!isMenuHidden && routeParams?.projectId && (
            <>
              <s.SectionName>
                <s.SectionNameText title={project?.name}>
                  {project?.name || ""}
                </s.SectionNameText>
              </s.SectionName>
              <s.VirtualDatacenter>
                <Typography>Virtual datacenter</Typography>
              </s.VirtualDatacenter>
              <ListItemButton
                sx={{ padding: "0" }}
                selected={ROUTES.PROJECT === currentRoute}
                data-testid={"container-drawer-menulist-item"}
                role={"listitem"}
              >
                <s.Link to={generatePath(ROUTES.PROJECT, routeParams)}>
                  <s.IconContainer>
                    <DashboardIcon />
                  </s.IconContainer>
                  <Typography>Overview</Typography>
                </s.Link>
              </ListItemButton>
              {renderMenuItems(inventoryMenuItems)}
              <s.MenuFooter />
            </>
          )}
        </s.DrawerMenuList>
      </s.Drawer>
      <Content>{children}</Content>
      {notification && (
        <Snackbar
          type={notification.type}
          isOpened={isSnackbarVisible}
          onClose={handleCloseSnackbar}
          onExited={handleSnackbarExited}
          title={notification.title}
          text={notification.text}
        />
      )}
    </s.Container>
  );
};
