import actionCreatorFactory from "typescript-fsa";
import {
  AcceptGroupInvitationParams,
  AcceptOrganizationInvitationParams,
  ChangeOrganizationOwnerParams,
  CreateGroupParams,
  CreateGroupResponse,
  CreateOrganizationParams,
  CreateOrganizationResponse,
  DeleteGroupInvitationParams,
  DeleteGroupParams,
  DeleteOrganizationInvitationParams,
  DeleteOrganizationParams,
  GetGroupInvitationParams,
  GetGroupInvitationResponse,
  GetGroupInvitationsParams,
  GetGroupInvitationsResponse,
  GetGroupParams,
  GetGroupResponse,
  GetGroupsParams,
  GetGroupsResponse,
  GetGroupUsersParams,
  GetGroupUsersResponse,
  GetOrganizationInvitationParams,
  GetOrganizationInvitationResponse,
  GetOrganizationInvitationsParams,
  GetOrganizationInvitationsResponse,
  GetOrganizationParams,
  GetOrganizationResponse,
  GetOrganizationsParams,
  GetOrganizationsResponse,
  GetOrganizationUsersParams,
  GetOrganizationUsersResponse,
  InviteUserToGroupParams,
  InviteUserToGroupResponse,
  InviteUserToOrganizationParams,
  InviteUserToOrganizationResponse,
  LeaveGroupParams,
  LeaveOrganizationParams,
  RegisterNewUserParams,
  RegisterNewUserResponse,
  RemoveUserFromGroupParams,
  RemoveUserFromOrganizationParams,
  SubmitHubSpotFormParams,
  UpdateGroupParams,
  UpdateGroupResponse,
  UpdateOrganizationParams,
  UpdateOrganizationResponse
} from "./types";

const actionCreator = actionCreatorFactory("ENTERPRISES");

export const getOrganization = actionCreator.async<
  GetOrganizationParams,
  GetOrganizationResponse,
  unknown
>("GET_ORGANIZATION");

export const getOrganizations = actionCreator.async<
  GetOrganizationsParams,
  GetOrganizationsResponse,
  unknown
>("GET_ORGANIZATIONS");

export const createOrganization = actionCreator.async<
  CreateOrganizationParams,
  CreateOrganizationResponse,
  unknown
>("CREATE_ORGANIZATION");

export const updateOrganization = actionCreator.async<
  UpdateOrganizationParams,
  UpdateOrganizationResponse,
  unknown
>("UPDATE_ORGANIZATION");

export const deleteOrganization = actionCreator.async<
  DeleteOrganizationParams,
  void,
  unknown
>("DELETE_ORGANIZATION");

export const leaveOrganization = actionCreator.async<
  LeaveOrganizationParams,
  void,
  unknown
>("LEAVE_ORGANIZATION");

export const changeOrganizationOwner = actionCreator.async<
  ChangeOrganizationOwnerParams,
  void,
  unknown
>("CHANGE_ORGANIZATION_OWNER");

export const getGroup = actionCreator.async<
  GetGroupParams,
  GetGroupResponse,
  unknown
>("GET_GROUP");

export const getGroups = actionCreator.async<
  GetGroupsParams,
  GetGroupsResponse,
  unknown
>("GET_GROUPS");

export const createGroup = actionCreator.async<
  CreateGroupParams,
  CreateGroupResponse,
  unknown
>("CREATE_GROUP");

export const updateGroup = actionCreator.async<
  UpdateGroupParams,
  UpdateGroupResponse,
  unknown
>("UPDATE_GROUP");

export const deleteGroup = actionCreator.async<
  DeleteGroupParams,
  void,
  unknown
>("DELETE_GROUP");

export const leaveGroup = actionCreator.async<LeaveGroupParams, void, unknown>(
  "LEAVE_GROUP"
);

export const getOrganizationUsers = actionCreator.async<
  GetOrganizationUsersParams,
  GetOrganizationUsersResponse,
  unknown
>("GET_ORGANIZATION_USERS");

export const getGroupUsers = actionCreator.async<
  GetGroupUsersParams,
  GetGroupUsersResponse,
  unknown
>("GET_GROUP_USERS");

export const removeUserFromOrganization = actionCreator.async<
  RemoveUserFromOrganizationParams,
  void,
  unknown
>("REMOVE_USER_FROM_ORGANIZATION");

export const removeUserFromGroup = actionCreator.async<
  RemoveUserFromGroupParams,
  void,
  unknown
>("REMOVE_USER_FROM_GROUP");

export const submitHubSpotForm = actionCreator.async<
  SubmitHubSpotFormParams,
  void,
  unknown
>("SUBMIT_HUBSPOT_FORM");

export const getOrganizationInvitations = actionCreator.async<
  GetOrganizationInvitationsParams,
  GetOrganizationInvitationsResponse,
  unknown
>("GET_ORGANIZATION_INVITATIONS");

export const getOrganizationInvitation = actionCreator.async<
  GetOrganizationInvitationParams,
  GetOrganizationInvitationResponse,
  unknown
>("GET_ORGANIZATION_INVITATION");

export const inviteUserToOrganization = actionCreator.async<
  InviteUserToOrganizationParams,
  InviteUserToOrganizationResponse,
  unknown
>("INVITE_USER_TO_ORGANIZATION");

export const acceptOrganizationInvitation = actionCreator.async<
  AcceptOrganizationInvitationParams,
  void,
  unknown
>("ACCEPT_ORGANIZATION_INVITATION");

export const deleteOrganizationInvitation = actionCreator.async<
  DeleteOrganizationInvitationParams,
  void,
  unknown
>("DELETE_ORGANIZATION_INVITATION");

export const getGroupInvitations = actionCreator.async<
  GetGroupInvitationsParams,
  GetGroupInvitationsResponse,
  unknown
>("GET_GROUP_INVITATIONS");

export const getGroupInvitation = actionCreator.async<
  GetGroupInvitationParams,
  GetGroupInvitationResponse,
  unknown
>("GET_GROUP_INVITATION");

export const inviteUserToGroup = actionCreator.async<
  InviteUserToGroupParams,
  InviteUserToGroupResponse,
  unknown
>("INVITE_USER_TO_GROUP");

export const acceptGroupInvitation = actionCreator.async<
  AcceptGroupInvitationParams,
  void,
  unknown
>("ACCEPT_GROUP_INVITATION");

export const deleteGroupInvitation = actionCreator.async<
  DeleteGroupInvitationParams,
  void,
  unknown
>("DELETE_GROUP_INVITATION");

export const registerNewUser = actionCreator.async<
  RegisterNewUserParams,
  RegisterNewUserResponse,
  unknown
>("REGISTER_NEW_USER");

export const clear = actionCreator<void>("CLEAR");
